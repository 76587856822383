import React, { useState, useEffect } from 'react';
import {
  message
} from 'antd';
import { Button } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import MRZLayout from "./Layout.jsx"
import { useNavigate } from "react-router-dom";
import { User } from '../api/user';
import { FindActivity } from '../api/findActivity';
import Cookies from 'js-cookie';

export default function Pin() {
  const navigate = useNavigate();

  const [pin, setPin] = useState('');
  const [ user, setUser ] = useState({})

  const getUserData = async () => {
    if (Cookies.get('userId')) {
      const result = await User({ _id: Cookies.get('userId') });
      
      if (!result?.error) {
        setUser(result)
      }
    }
  };

  useEffect(() => {
    getUserData();
  /* eslint-disable-next-line */
  }, [Cookies.get('userId')]);

  const submitPin = async() => {

    let r_pin = pin

    let result = await FindActivity({
      merchantId: user.merchantId,
      pin: r_pin, 
      status: 'unauthorized',
      endTime: {
        $gt: new Date()
      },
      $limit: 1,
    })
    
    console.log(result)

    if(result){

      Cookies.set('activityId', result?._id);
      navigate(`/activity`)

    }else{
      message.error(`Can't find Pin ${r_pin}`)
      setPin('')
    }
  };

  const cancel = () => {
    navigate('/main')
  }

  return (
    <MRZLayout>
        <p 
          className='text-center m-auto mb-2 mt-4'
          style={{
            fontWeight: 'bold',
            color: '#3b7779',
          }}
        >
          Enter PIN Code
        </p>
        <div className='standard-width m-auto'>
          <div
            className='pb-2'
            style={{ 
              width: '100%',
              backgroundColor: 'rgb(246, 246, 246)',
              borderRadius: 8,
              paddingTop: 30,
              marginBottom: 20
            }}
          >
          <PinInput 
            length={4} 
            initialValue=""
            // secret
            // secretDelay={100} 
            onChange={(value, index) => setPin(value)} 
            type="numeric" 
            inputMode="number"
            style={{padding: '10px', display: 'flex', justifyContent: 'center'}} 
            inputStyle={{border: 'none', borderBottom: 'solid 1px'}}
            // inputFocusStyle={{borderColor: 'blue'}}
            onComplete={(value, index) => {}}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
  
          </div>

          <div className='d-flex mb-4'>
            <Button 
              style={{
                width: '100%',
                backgroundColor: '#E15655',
                borderColor: '#E15655'
              }} 
              onClick={cancel}>
              <p
                className='mb-0' 
                style={{
                  fontWeight: "700",
                  color: '#FFFFFF'
                }}>Cancel</p>
            </Button>

            <Button 
              style={{
                width: '100%',
                marginLeft: 6,
                backgroundColor: '#FDB256',
                borderColor: '#FDB256'
                }} 
              onClick={() => submitPin()} 
              disabled={pin.length !== 4}
            >
              <p 
                className='mb-0'
                style={{
                  fontWeight: "700",
                  color: '#FFFFFF'
                }}>Confirm</p>
            </Button>
          </div>
        </div>
    </MRZLayout>
  )
}