import axios from 'axios';
import Cookies from 'js-cookie';

export const Logout = (accessToken) => axios.request({
  method: 'DELETE',
  url: `${process.env.REACT_APP_API_URL}/authentication`,
  headers: { 
    'Content-Type': 'application/json',
    Authorization: accessToken 
  },
})
.then((response) => {
  Cookies.remove('accessToken');
  Cookies.remove('userId');
  Cookies.remove('username');
  return response
})
.catch((error) => {
  console.log({ error });
  return false;
});
