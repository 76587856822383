import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css'
import 'antd/es/message/style/index.css';

import SignIn from './pages/SignIn.jsx';
import Main from './pages/Main.jsx';
import Scan from './pages/Scan.jsx';
import Pin from './pages/Pin.jsx';
import Activity from './pages/Activity.jsx';
import ShowCampaign from './pages/ShowCampaign.jsx';
import Result from './pages/Result.jsx';

const MyContext = React.createContext(); 
export { MyContext };

function LayoutContext(props) {
  const [ notify, setNotify ] = useState(false)
  const [ message, setMessage ] = useState("")
  const [ loading, setLoading ] = useState(false)

  const handleNotify = (value, message) => {
    setNotify(value)
    setMessage(message)
  };

  return (
    <MyContext.Provider value={{ loading, setLoading, notify, message, handleNotify }}>
      {props.children}
    </MyContext.Provider>
  );
}

ReactDOM.render(
  <React.Fragment>
    <LayoutContext>
      <MyContext.Consumer>
        {value => 
          <RouterProvider router={createBrowserRouter([
            {
              path: "/",
              element: <SignIn {...value} />,
            },
            {
              path: "/main",
              element: <Main {...value} />,
            },
            {
              path: "/scan",
              element: <Scan {...value} />,
            },
            {
              path: "/pin",
              element: <Pin {...value} />,
            },
            {
              path: "/activity",
              element: <Activity {...value} />,
            },
            {
              path: "/showCampaign",
              element: <ShowCampaign {...value} />,
            },
            {
              path: "/result",
              element: <Result {...value} />,
            },
          ])} />  
        }
      </MyContext.Consumer>
    </LayoutContext>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
