import React, { useState, useEffect } from 'react';
import {
  message
} from 'antd';
import { Button } from 'react-bootstrap';
import QrReader from 'react-qr-scanner'
import MRZLayout from "./Layout.jsx"
import Cookies from 'js-cookie';
import { FindActivity } from '../api/findActivity';
import { User } from '../api/user';
import { useNavigate } from "react-router-dom";

export default function Scan(props) {
  const navigate = useNavigate();
  const [ user, setUser ] = useState({})
  const [pin, setPin] = useState('');
  const [facingMode, setFacingMode] = useState('environment');

  const getUserData = async () => {
    if (Cookies.get('userId')) {
      const result = await User({ _id: Cookies.get('userId') });
      
      if (!result?.error) {
        setUser(result)
      }
    }
  };

  useEffect(() => {
    getUserData();
  /* eslint-disable-next-line */
  }, [Cookies.get('userId')]);

  const handleScan = (e) => {
    if(e?.text){
      setPin(e?.text)
    }
  }

  const submitPin = async() => {

    let r_pin = pin

    let result = await FindActivity({
      merchantId: user.merchantId,
      pin: r_pin, 
      status: 'unauthorized',
      endTime: {
        $gt: new Date()
      },
      $limit: 1,
    })
    
    console.log(result)

    if(result){

      Cookies.set('activityId', result?._id);
      navigate(`/activity`)

    }else{
      message.error(`Can't find Pin ${r_pin}`)
      setPin('')
    }
  };

  useEffect(() => {
    if(pin)
    submitPin(pin)
  /* eslint-disable-next-line */
  }, [pin])

  const cancel = () => {
    navigate('/main')
  }

  /* eslint-disable-next-line */
  const handleFacingMode = () => {
    if(facingMode === 'front'){
      setFacingMode('rear')
    }else{
      setFacingMode('front')
    }
  }

  return (
    <MRZLayout>
      <p 
        className='text-center m-auto mb-2 mt-4'
        style={{
          fontWeight: 'bold',
          color: '#3b7779',
        }}
      >
        Scan QR Code
      </p>
      <div className='position-relative mb-4'>
        <QrReader
          constraints={{
            video: {
              facingMode: { exact: facingMode }
            }
          }
          }
          facingMode={facingMode}
          delay={2000}
          style={{width: '100%'}}
          onError={(e) => console.log(e)}
          onScan={handleScan}
        />
      {/* <div 
        className='position-absolute'
        style={{
          bottom: 10,
          right: 10
        }}
        onClick={() => handleFacingMode()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
          <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
        </svg>
        </div> */}
      </div>

      <div className='standard-width m-auto mb-4'>
        <Button 
          style={{
            backgroundColor: '#E15655',
            borderColor: '#E15655',
          }} 
          className='w-100'
          onClick={cancel}
        >
          <p
          className='mb-0' 
            style={{
              fontWeight: 'bold',
              color: '#FFFFFF'
            }}>Cancel</p>
        </Button>
      </div>

    </MRZLayout>
  )
}