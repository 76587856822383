import React, {useEffect, useState} from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import moment from "moment"
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import MRZLayout from "./Layout"
import { Link } from 'react-router-dom';
import { User } from '../api/user';
import { GetInteraction } from '../api/interaction';
import { GetBranches } from '../api/getBranches';

export default function Main(props) {
  const [ user, setUser ] = useState({})
  const [ interaction, setInteraction ] = useState(0)
  const [ branch, setBranch ] = useState({})

  const getUserData = async () => {
    if (Cookies.get('userId')) {
      const result = await User({ _id: Cookies.get('userId') });
      
      if (!result?.error) {
        setUser(result)
        fetchInteraction(result.merchantId)
      }
    }
  };

  const fetchInteraction = async(merchantId) => {
    let result = await GetInteraction({merchantId,})
    
    if(result.total){
      setInteraction(result.total)
    }
  }

  const getBranch = async () => {
    const result = await GetBranches({ $limit: 1 });
    if (result.data) {
      setBranch(result.data[0]);
    }
  };

  useEffect(() => {
    getBranch();
  }, []);
  
  useEffect(() => {
    getUserData();
  /* eslint-disable-next-line */
  }, [Cookies.get('userId')]);

  const OpenWEB = () => {
    window.open('https://app.morerewardz.com/');
  }
 
  return (
    <MRZLayout>

      <div className='standard-width m-auto mb-4'>
        <p 
          className='text-center m-auto mb-2 mt-4'
          style={{
            fontWeight: 'bold',
            color: '#3b7779',
          }}
        >
          User Details
        </p>
        <div 
          style={{
            width: '100%',
            backgroundColor: 'rgb(246, 246, 246)',
            borderRadius: 8
          }}
          className='px-4 py-3'
        >
          <div className='m-auto text-center w-100'>
            <Image 
              src={
                user.profilePicURL?
                `${process.env.REACT_APP_API_URL}/${user.profilePicURL}`
                :
                require('../assets/images/avatar.png')
              } 
              fluid 
              width={90}
              className='mb-2'
              style={{
                borderRadius: 50,
                borderColor: 'white',
                borderWidth: 2,
                backgroundColor: 'white'
            }} />
          </div>
          <div className='user-details-wrapper'>
            <div className='row flex justify-content-center'>
              <div className='col-5'>
                <p>Merchant ID</p>
              </div>
              <div className='col-6'>
                <p>: {user.merchantName || "-"}</p>
              </div>
            </div>
            <div className='row flex justify-content-center'>
              <div className='col-5'>
                <p>Staff ID</p>
              </div>
              <div className='col-6'>
                <p>: {user.username}</p>
              </div>
            </div>
            <div className='row flex justify-content-center'>
              <div className='col-5'>
                <p>Last Login</p>
              </div>
              <div className='col-6'>
                <p>: {moment(user.lastLogin).format('DD/MM/YYYY-hh:mm')}</p>
              </div>
            </div>
            <div className='row flex justify-content-center'>
              <div className='col-5'>
                <p>Interaction</p>
              </div>
              <div className='col-6'>
                <div className='d-flex align-items-center gap-1'>
                  <p>
                    : {interaction}/{ (user?.merchantInfo?.subscription && branch?.subscription)? branch?.subscription[user?.merchantInfo?.subscription]?.interaction: 0 }
                  </p>
                  {
                    !isEmpty(user.merchantInfo)? 
                      (user?.merchantInfo?.subscription !== 'gold'? (
                        <Button 
                          className='border-0 d-flex'
                          style={{
                            backgroundColor: '#FDB256',
                            padding: '2px 3px'
                          }} 
                          onClick={() => OpenWEB()}
                        >
                          <>
                            <span 
                              className='mb-0 text-4xs'
                              style={{
                                fontWeight: "700",
                              }}
                            >
                                Upgrade
                              </span>
                          </>
                        </Button>
                      ): null)
                      :
                      null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='standard-width m-auto text-center'>
        <p 
          className='text-sm text-center m-auto mb-2'
          style={{
            fontWeight: 'bold',
            color: '#3b7779',
          }}
        >
          Authenticate
        </p>
        <Link to="/scan">
          <Button 
            className='border-0 w-100 py-2 mb-2'
            style={{
              position: 'relative',
              backgroundColor: '#FDB256',
              borderColor: '#FDB256'
            }}
          >
            <>
              <Image 
                src={require('../assets/images/qrcode.png')} 
                fluid
                width={25}
                className='me-2'
              />
              <Form.Text style={{
                fontWeight: "700",
                color: '#FFFFFF'
                }}>Scan QR Code</Form.Text>
            </>
          </Button>
        </Link>

        <p 
          className='text-sm text-center m-auto mb-2'
          style={{
            fontWeight: 'bold',
            color: '#3b7779',
          }}
        >
          Or
        </p>

          <div className='mb-4'>
            <Link to="/pin">
              <Button 
                style={{
                  backgroundColor: '#3B7779',
                  borderColor: '#3B7779'
                }}
                className='me-2 w-100 py-2 mb-4'
              >
                <Image 
                  src={require('../assets/images/pin.png')} 
                  fluid
                  width={16}
                  className='me-2'
                />
                <Form.Text 
                  style={{
                    fontWeight: "700",
                    color: '#FFFFFF'
                  }}
                >
                  Enter PIN Code</Form.Text>
              </Button>
            </Link>

            <Link to="/showCampaign">
              <Button 
                style={{
                  backgroundColor: 'transparent',
                  borderColor: '#3B7779',
                  borderWidth: 1,
                }} 
                className='p-0 px-2 pb-1'
              >
                <Image 
                    src={require('../assets/images/eye.png')} 
                    fluid
                    width={20}
                    className='me-1'
                  />
                <Form.Text style={{
                  // fontWeight: "700",
                  fontSize: 10,
                  // lineHeight: 12,
                  color: '#3B7779',
                }}>Show Campaign Page</Form.Text>
              </Button>
            </Link>
          </div>
        </div>
    </MRZLayout>
  )
}