import axios from 'axios';

export const FindActivity = (data) => axios
  .request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/activities`,
    params: {
      ...data,
    },
  })
  .then((response) => response?.data?.data?.[0])
  .catch((error) => error);