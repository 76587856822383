import axios from 'axios';
const Cookies = require('js-cookie');

export const Customer = (data) => axios
  .get(`${process.env.REACT_APP_API_URL}/users/${data?._id}`, {
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
    },
  })
  .then((response) => response?.data)
  .catch((error) => console.log({ error }));
