import axios from 'axios';
const Cookies = require('js-cookie');

export const GetActivity = (data) => axios
  .get(`${process.env.REACT_APP_API_URL}/activities/${data?._id}`, {
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
    },
  })
  .then((response) => response?.data)
  .catch((error) => error);