import React from 'react';
import {
  message, Form, Input,
} from 'antd';
import { Button, Image, Spinner } from 'react-bootstrap';
import MRZLayout from "./Layout"
import { Auth } from '../api/auth.js';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

export default function SignInScreen(props){
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async(values) => {
    
    const result = await Auth({ ...values, strategy: 'merchant-rep-local' });
    
    if (result?.data?.accessToken) {  
      Cookies.set('accessToken', result?.data?.accessToken);
      Cookies.set('userId', result?.data?.user?._id);
      Cookies.set('username', result?.data?.user?.username);

      navigate('/main')
    } else {
      message.error(result.message)
      // props.handleNotify(true, "")
    }
  }

  return (
    <MRZLayout class={'mrz-bg-primary'}>
    <div>
        <div className='sign-in-container'>
          <Image width={150} src={require('../assets/images/logo.png')} fluid/>
        </div>

        <div className='m-auto text-center flex flex-column justify-content-center align-items-center py-4 standard-width'>
          <Form
            name='form'
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              name='merchantId'
              rules={[
                {
                  required: true,
                  message:
                    'Please input your Merchant ID!',
                },
              ]}
            >
              <Input
                className='signin-input w-100 py-2'
                placeholder='Merchant ID'
                autocapitalize="none"
              />
            </Form.Item>
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message:
                    'Please input your Staff ID!',
                },
              ]}
            >
              <Input
                className='signin-input w-100 py-2'
                placeholder='Staff ID'
                autocapitalize="none"
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input
                type='password'
                className='signin-input w-100 py-2'
                placeholder='Enter Password'
              />
            </Form.Item>

            <div 
              className='text-end d-flex justify-content-end'
              disabled={props.loading}
            >
              <p 
                className='text-xs fw-medium'
                style={{
                  color: '#3B7779',
                  cursor: "pointer"
                }}
                onClick={() => {
                  message.success('Please contact your administrator for further assistance!')
                }} 
              >
                Forgot Password
              </p>
            </div>

            <Button 
              className='border-0 w-100 py-2 mb-4'
              style={{
                backgroundColor: '#FDB256',
              }} disabled={props.loading}
              type='submit'
            >
              {props.loading?
                <Spinner animation="grow" />
                :
                <p 
                  className='mb-0 px-2'
                  style={{
                    fontWeight: "700",
                  }}
                >
                  Login
                </p>
              }
            </Button>
            
          </Form>
        </div>

    </div>
    </MRZLayout>
  )
}