import axios from 'axios';
const Cookies = require('js-cookie');

export const Patch = (data) => axios
  .request({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}/activities/${data?._id}`,
    headers: {
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
    },
    data,
  })
  .then((response) => response?.data)
  .catch((error) => (error));
