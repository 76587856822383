import axios from 'axios';

export const GetBranches = (params) => axios
  .get(`${process.env.REACT_APP_API_URL}/branches`, {
    params,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then((response) => response?.data)
  .catch((error) => error);