import React, { useEffect, useContext } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Logout } from '../api/logout';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import { MyContext } from "../index"
import { Verify } from '../api/auth';

export default function Layout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { 
    // loading, setLoading, 
    notify, message, handleNotify 
  } = useContext(MyContext);
  
  const verifyToken = async () => {
    if (Cookies.get('accessToken')) {
      const result = await Verify({ accessToken: Cookies.get('accessToken') });
      if (result?.data === 'Authenticated') {
        
      } else {
        Cookies.remove('accessToken');
        Cookies.remove('userId');
        Cookies.remove('username');
        Cookies.remove('activityId');
      }
    }else{
      navigate("/");
    }
  };

  useEffect(() => {
    verifyToken()
    /* eslint-disable-next-line */
  }, [])

  const logout = () => {
    Logout(Cookies.get('accessToken'));
    setTimeout(() => {
      Cookies.remove('accessToken');
      Cookies.remove('userId');
      Cookies.remove('username');
      navigate("/");
    }, 500);
  }

  return (
    <div 
      className={`shadow-sm d-flex m-auto ${props.class? props.class  : 'bg-white'}`}
      style={{
        maxWidth: 388,
        minHeight: '100vh',
        flexDirection: 'column'
      }}
    >
    <Toast 
      className='position-absolute start-0 end-0 m-auto'
      style={{top: 10}}
      onClose={() => {
        handleNotify(false, "")
      }} 
      show={notify} 
      delay={3000} 
      autohide
    >
      <Toast.Header>
        {/* <img
          src="holder.js/20x20?text=%20"
          className="rounded me-2"
          alt=""
        /> */}
        <strong className="me-auto">Note:</strong>
        {/* <small>11 mins ago</small> */}
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
    <div>
      {
        (location.pathname !== "/") &&
        <div className='py-2 row gx-0 px-2 mrz-bg-primary'>
          <div className='col-6 d-flex align-items-center'>
            <Image width={140} src={require('../assets/images/menubar-logo.png')} fluid/>
          </div>

          <div className='col-6 d-flex justify-content-end'>
            <Button 
              className='border-0'
              style={{
                width: 80,
                backgroundColor: 'transparent',
              }} 
              onClick={() => logout()}
            >
              <Image 
                src={require('../assets/images/logout.png')} 
                fluid/>
            </Button>
          </div>
        </div>
        }

        {props.children}

        <div className='d-flex justify-content-center'>
          <Image className='w-50' src={require('../assets/images/bottom-logo.png')} fluid/>
        </div>

      </div>
    </div>
  )
}
