import axios from 'axios';
const Cookies = require('js-cookie');

export const GetInteraction = (data) => axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/getMerchantInteraction`,
    headers: { 
      Authorization: `Bearer ${
        Cookies.get('accessToken') || data?.accessToken
      }`,
      'Content-Type': 'application/json' 
    },
    data,
  })
  .then((response) => response?.data)
  .catch((error) => console.log({ error }));
