import axios from 'axios';

export const Auth = (data) => axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/authentication`,
    headers: { 'Content-Type': 'application/json' },
    data,
  })
  .then((response) => response)
  .catch((error) => error?.response?.data);

 export const Verify = (data) => axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/auth/verify`,
    headers: { 'Content-Type': 'application/json' },
    data,
  })
    .then((response) => response)
    .catch((error) => error?.response?.data?.message);
