import React, { useState, useEffect } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import MRZLayout from "./Layout.jsx"
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { GetActivity } from '../api/getActivity';
import { User } from '../api/user';
import { Customer } from '../api/customer';
import { Patch } from '../api/patchActivity';
import Cookies from 'js-cookie';
import { GetBranches } from '../api/getBranches';
import {
  Input,
  message
} from 'antd';

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const ReverseTransaction = ({activity}) => {
  return activity.action !== 'reverse'?
    <div style={{paddingVertical: 4}}></div>
    :
    <div 
      style={{
        backgroundColor: 'rgb(238, 57, 41)',
        width: '100%',
      }}
    >
      <p 
      className='mb-0'
      style={{
        fontWeight: 'bold',
        fontSize: 12,
        color: 'white',
      }}>
        Reverse Transaction
      </p>
    </div>
}

export default function Activity() {
  const navigate = useNavigate();
  const [ user, setUser ] = useState({})
  const [reverse, setReverse] = useState({})
  const [customer, setCustomer] = useState({})
  const [activity, setActivity] = useState({campaignType: "stamp"})
  const [counts, setCounts] = useState(1)
  const [remarks, setRemarks] = useState('');
  const [ branch, setBranch ] = useState({})
  const activityId = Cookies.get('activityId');
  
  const getData = async(id) => {
    let result = await GetActivity({_id: id})
    
    if(result){
      setActivity(result)
      let resultCustomer = await Customer({_id: result.createdBy})
      setCustomer(resultCustomer)
      if(result.action === "reverse"){
        let resultReverse = await GetActivity({_id: result.reverseId})
        console.log(resultReverse)
        setReverse(resultReverse)
      }
    }
  }

  const getUserData = async () => {
    if (Cookies.get('userId')) {
      const result = await User({ _id: Cookies.get('userId') });
      
      if (!result?.error) {
        setUser(result)
      }
    }
  };

  useEffect(() => {
    getUserData();
  /* eslint-disable-next-line */
  }, [Cookies.get('userId')]);

  const getBranch = async () => {
    const result = await GetBranches({ $limit: 1 });
    if (result.data) {
      setBranch(result.data[0]);
    }
  };

  useEffect(() => {
    getBranch();
  }, []);

  useEffect(() => {
    if(!activityId){
      navigate('/main')
    }else{
      getData(activityId)
    }
  /* eslint-disable-next-line */
  }, [activityId])

  const authorize = async (status) => {
    let result = await Patch({
      _id: activity._id,
      status,
      pin: 'AUTH',
      counts,
      repId : user._id,
      repName : user.username,
      remarks,

      // pass data
      merchantId: user.merchantId,
      maxInteraction: (branch?.subscription[user?.merchantInfo?.subscription]?.interaction)
    })
    console.log(result)
    if(!result.error){
      if (result.status === 'authorized') {

        navigate(`/result?status=success`)
        Cookies.remove('activityId');

      }else{
        navigate(`/main`)
        Cookies.remove('activityId');
      }
    }else{
      console.log(result.error)
      message.error(`Stamp is not valid!`)
    }
  };

  const minusCount = () => {
    if(counts > 1)
    setCounts(counts-1)
  }

  const plusCount = () => {
    if(counts+1 <= activity.countLeft)
    setCounts(counts+1)
  }

  return (
    <MRZLayout>
        <div className='standard-width m-auto'>
          <p 
            className='text-center m-auto mb-2 mt-4'
            style={{
              fontWeight: 'bold',
              color: '#3b7779',
            }}
          >
            Transaction Details
          </p>
          <div 
            style={{
              width: '100%',
              backgroundColor: 'rgb(246, 246, 246)',
              borderRadius: 8
            }}
            className='px-4 py-3'
          >
            <div className='m-auto text-center w-100'>
                <ReverseTransaction activity={activity} />
                <div style={{width: 90}} className='m-auto'>
                  <Image 
                  className='rounded-circle'
                    src={
                      customer?.profilePicURL?
                      `${process.env.REACT_APP_API_URL}/${customer?.profilePicURL}`
                      :
                      require('../assets/images/avatar.png')
                    } fluid />
                </div>

              <div className='user-details-wrapper'>
                <div style={styles.info}>
                  <p>Email</p>
                  <h6>{(activity.customer_email || '-')}</h6>
                </div>
                <div style={styles.info}>
                  <p>Name</p>
                  <h6>{
                    customer.lastName || customer.firstName?
                    ((customer.lastName? customer.lastName: '') + " " + (customer.firstName? customer.firstName: ''))
                    : '-'
                  }</h6>
                </div>
                <div>
                  <p>Campaign Tool</p>
                  <h6>
                    {activity.redeem? "Redeem ":""}
                    {
                      activity.campaignType === 'stamp'?
                      "Stamp Card"
                      :
                      capitalizeFirstLetter(activity.campaignType)
                    }
                  </h6>
                </div>
                <div>
                  <p>Campaign Name</p>
                  <h6>{(activity.title)}</h6>
                </div>

                {
                activity.amount? (
                  <div>
                    <p>Amount</p>
                    <h6>
                    {activity.currency + " " + activity.amount}
                    </h6>
                  </div>
                ) : null
                }
                </div>

                <ReverseTransaction activity={activity} />

              </div>
            </div>

            {
              activity.action === 'reverse'?
              <div className='mb-2'>
                <div className='mb-1'>
                  <p 
                  className='text-2xs mb-0'
                    style={{
                      textAlign: 'center',
                      fontWeight: "600",
                      color: 'rgb(238, 57, 41)'
                    }}>Date - Time</p>
                  <p 
                    className='text-xs mb-0'
                    style={{
                      textAlign: 'center',
                      fontWeight: "600",
                      color: 'rgb(238, 57, 41)'
                    }}>
                      {
                        moment(reverse.createdAt).format('DD/MM/YYYY - hh:mm')
                      }
                    </p>
                </div>

                <div className='mb-1'>
                  <p
                    className='text-2xs mb-0' 
                    style={{
                      textAlign: 'center',
                      fontWeight: "600",
                      color: 'rgb(238, 57, 41)'
                    }}>Staff ID</p>
                  <p 
                    className='text-xs mb-0'
                    style={{
                      textAlign: 'center',
                      fontWeight: "600",
                      color: 'rgb(238, 57, 41)'
                    }}>
                      {
                        reverse.merchantName &&
                        capitalizeFirstLetter(reverse.merchantName)
                      }
                    </p>
                </div>

                <div className='mb-1'>
                  <p 
                    className='text-2xs mb-0'
                    style={{
                      textAlign: 'center',
                      fontWeight: "600",
                      color: 'rgb(238, 57, 41)'
                    }}>Remarks</p>
                  <p 
                    className='text-xs mb-0'
                    style={{
                      textAlign: 'center',
                      fontWeight: "600",
                      color: 'rgb(238, 57, 41)'
                    }}>
                      {
                        (reverse.remarks || '-')
                      }
                    </p>
                </div>
              </div>
              :
              null
            }

            {
              activity.campaignType === "stamp" && !activity.redeem && activity.action !== 'reverse' ? (
                <div className='m-auto text-center'>
                  <p
                  className='text-sm my-2'
                    style={{
                      fontWeight: '400',
                      color: '#3b7779',
                    }}>Enter number of stamp(s)
                  </p>
                  <div>
                  <div 
                    className='d-flex align-items-center justify-content-center m-auto mb-3'
                    style={{width: 100}}
                  >
                  <Button 
                    style={{
                      backgroundColor: 'transparent',
                    }} 
                    className='p-0 w-100 border-0'
                    onClick={() => minusCount()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-dash-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/>
                      </svg>
                  </Button>
                  <Input 
                    type="number" 
                    className='text-center m-0 p-0 border-0' 
                    value={counts} placeholder='eg: 0'
                  />
                  <Button 
                    style={{
                      backgroundColor: 'transparent',
                    }} 
                    className='p-0 w-100 border-0'
                    onClick={() => plusCount()}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-plus-lg" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                    </svg>
                  </Button>
                    </div>
                  </div>
                </div>
              ): null
            }

            <Form.Control 
              className='py-2' 
              placeholder='Remarks / Notes / Invoice / Receipt' 
              onChange={(e) => setRemarks(e.target.value)} value={remarks}
            />

            <div className='mb-4 d-flex'>
              <Button 
                style={{
                  marginRight: 3,
                  backgroundColor: '#E15655',
                  borderColor: '#E15655',
                }} 
                className='py-2 w-100'
                onClick={() => authorize('declined')}
                >
                <p 
                  className='mb-0'
                  style={{
                    fontWeight: "700",
                    color: '#FFFFFF'
                  }}>Decline</p>
              </Button>
              <Button 
                style={{
                  marginLeft: 3,
                  backgroundColor: '#FDB256',
                  borderColor: '#FDB256',
                }} 
                className='py-2 w-100'
                onClick={() => authorize('authorized')}>
                <p 
                  className='mb-0'
                  style={{
                    fontWeight: "700",
                    color: '#FFFFFF'
                  }}>Confirm</p>
              </Button>
            </div>
          </div>
    </MRZLayout>
  )
}

const styles = {
  picture: {
    // marginTop: 18,
    marginBottom: 10,
    alignSelf: 'center',
    width: 90,
    height: 90,
    borderRadius: 50,
    backgroundColor: 'white',
    // borderWidth: 2
  },
  info: {
    // flexDirection: "column",
    // alignContent: "space-between",
    marginBottom: 6
  },
  info_label: {
    color: '#3b7779',
    fontStyle: 'normal',
    fontWeight: "400",
    textAlign: 'center'
  },
  info_value: {
    fontStyle: 'normal',
    fontWeight: "500",
    textAlign: 'center'
  },
  space_between: {
    marginTop: 0,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  remarks: {
    width: '100%',
    borderColor: 'rgba(0, 0, 0, 0.06)',
    borderWidth: 1,
    // height: 30,
    paddingBottom: 16,
    textAlign: 'center'
    // marginTop: 10
  }
};
