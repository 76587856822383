import React from 'react';
import { Button, Image } from 'react-bootstrap';
import MRZLayout from "./Layout.jsx"
import { useNavigate, useLocation } from "react-router-dom";

export default function Result() {
  const navigate = useNavigate();
  const location = useLocation();
  const status = new URLSearchParams(location.search).get('status');

  const done = () => {
    navigate('/main')
  };

  return (
    <MRZLayout>

        <div className='m-auto standard-width text-center mt-4'>
            {
              status === 'success'? (
                <Image 
                  src={require('../assets/images/success.png')} 
                  fluid
                  style={{width: 150}}
                />
              ): (
                <Image 
                  src={require('../assets/images/fail.png')} 
                  fluid
                  style={{width: 150}}
                />
              )
            }
          <div style={{marginBottom: 30}}>
            <h4 className='fw-bold mb-0'>Authentication is</h4>
            <h4 className='fw-bold'>{status === 'success' ? 'Successful' : 'Unsuccessful'}</h4>
          </div>
          
          <Button 
            style={{
              width: 140,
              backgroundColor: '#E15655',
              borderColor: '#E15655',
            }} 
            className='mb-4'
            onClick={() => done()}
          >
            <p 
              className='mb-0'
              style={{
                fontWeight: '700',
                color: '#FFFFFF'
              }}>
                Close
              </p>
          </Button>
        </div>

    </MRZLayout>
  )
}