import React, { useState, useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import MRZLayout from "./Layout.jsx"
import { useNavigate } from "react-router-dom";
import { User } from '../api/user';
import Cookies from 'js-cookie';

export default function Main(props) {
  const navigate = useNavigate();
  const [ user, setUser ] = useState({})

  const getUserData = async () => {
    if (Cookies.get('userId')) {
      const result = await User({ _id: Cookies.get('userId') });
      
      if (!result?.error) {
        setUser(result)
      }
    }
  };

  useEffect(() => {
    getUserData();
  /* eslint-disable-next-line */
  }, [Cookies.get('userId')]);

  return (
    <MRZLayout>

      <div className='standard-width m-auto text-center'>
        <p 
          className='text-center m-auto mb-2 mt-4 text-sm'
          style={{
            fontWeight: 'bold',
            color: '#3b7779',
          }}
        >
          Scan now & start enjoying the rewardz from
        </p>
        <p 
          style={{
            alignSelf: 'center',
            fontWeight: 'bold',
            pTransform: 'capitalize',
            marginBottom: 12
          }}
        >
          {user.merchantName || "-"}
        </p>

          <div className='row d-flex justify-content-center gx-0 mb-2'>
            <div className='col-3'>
              <Image 
                src={require('../assets/images/icon-scan.png')} 
                fluid
              />
            </div>
            <div className='col-3'>
              <Image 
                src={require('../assets/images/icon-sign-up.png')} 
                fluid
              />
            </div>
            <div className='col-3'>
              <Image 
                src={require('../assets/images/icon-enjoy.png')} 
                fluid
              />
            </div>
          </div>

          <div style={{alignSelf: 'center', marginVertical: 12}}>
            <QRCode
              size={250}
              value={`https://go.morerewardz.com/${user.merchantName}`}
              renderAs="canvas"
            />
          </div>

          <p 
            style={{
              alignSelf: 'center',
              fontWeight: '500',
              color: '#3B7779',
            }}
          >
            Or enter URL in browser
          </p>

          <p 
            style={{
              alignSelf: 'center',
              fontWeight: 'bold',
              marginTop: 6,
              marginBottom: -6
            }}
          >
            https://go.morerewardz.com/{user.merchantName}
          </p>

          <Button 
            style={{
              backgroundColor: '#E15655',
              borderColor: '#E15655',
              borderWidth: 1,
            }} 
            className='my-4 w-100'
            onClick={() => navigate('/main')}
            >
            <p 
              style={{
                fontWeight: "700",
                color: '#FFFFFF',
              }}
              className='mb-0'
            >
              Back
            </p>
          </Button>
        </div>
            
    </MRZLayout>
  )
}